import React from 'react'
import styled from 'styled-components'

interface IErrorMessage {
  title: string
  radioError?: boolean
  subtitle?: any
}

interface IError {
  radioError?: boolean
}

const ErrorMessage = ({ title, radioError, subtitle }: IErrorMessage) => {
  return (
    <Container>
      <ErrorTitle radioError={radioError}>{title}</ErrorTitle>
      {subtitle && <p>{subtitle}</p>}
    </Container>
  )
}

const Container = styled.div`
  & p {
    color: #d0021b;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;

    @media only screen and (max-width: 850px) {
      line-height: 24px;
      font-size: 14px;
    }

    & a,
    & a:link,
    a:visited {
      font-size: inherit;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }
`

const ErrorTitle = styled.section<IError>`
  color: #d0021b;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  margin-top: 10px;

  animation-name: shake;
  animation-duration: 1s;
  animation-iteration-count: 1;

  ${props =>
    props.radioError &&
    `
    margin-top: -25px;
    margin-bottom: 35px;
    `};

  @media only screen and (max-width: 850px) {
    line-height: 24px;
    font-size: 14px;
  }
`

export default ErrorMessage
