import React, { useState } from 'react'
import styled from 'styled-components'
import ErrorMessage from './ErrorMessage'
import color from '../style/color'
import { isTablet, isMobile } from 'react-device-detect'

interface InputProps {
  value: string
  onChange: any
  label: string
  isValidated: boolean
  noMarginBottom?: boolean
  noPaddingTop?: boolean
  placeholder?: string
  width?: number
  onBlur?: any
  hasError?: boolean
  errorTitle?: string
  id?: string
  subtitle?: any
  autoFocus?: boolean
}

interface InputElementProps {
  isValidated: boolean
  hasError: boolean
}

interface IInputContainerProps {
  noMarginBottom?: boolean
  noPaddingTop?: boolean
  focused: boolean
  optional: boolean
}

const Input = ({
  value,
  onChange,
  label,
  isValidated,
  noMarginBottom,
  noPaddingTop,
  placeholder,
  width,
  onBlur,
  hasError = false,
  errorTitle = '',
  id,
  optional,
  autoFocus,
  subtitle
}: InputProps) => {
  const [focused, toggleFocused] = useState(false)

  return (
    <InputContainer noPaddingTop={noPaddingTop} noMarginBottom={noMarginBottom} focused={focused}>
      <Anchor id={id} />
      <label>
        {label}
        {optional && <span> (Optional)</span>}
      </label>
      <SmallContainer>
        <InputElement
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          isValidated={isValidated}
          width={width}
          onBlur={() => {
            onBlur()
            toggleFocused(false)
          }}
          onFocus={() => {
            toggleFocused(true)
          }}
          hasError={hasError}
        />
      </SmallContainer>
      {hasError && <ErrorMessage title={errorTitle} subtitle={subtitle} />}
    </InputContainer>
  )
}

const SmallContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Anchor = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`

const InputContainer = styled.div<IInputContainerProps>`
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  flex: 1;
  flex-shrink: 0;
  ${props => props.noPaddingTop && `padding-top: 0`};
  ${props => props.noMarginBottom && `margin-bottom: 0`};
  position: relative;

  @media only screen and (max-width: 850px) {
    padding-top: 24px;
  }

  & label {
    color: ${color.text};
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 9px;
    ${props => props.focused && `color: ${color.primary} !important`};

    & span {
      font-style: italic;
    }
  }
`

const InputElement = styled.input<InputElementProps>`
  height: 60px;
  width: 100%;
  flex-shrink: 0;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.9rem;
  font-weight: 500;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 200ms ease, border-color 200ms ease, box-shadow 200ms ease;
  ${props => props.width && `width ${props.width}px`};

  &:focus {
    outline: none;
    border-color: ${color.primary};
  }

  ::placeholder {
    color: #aaa !important;
    font-weight: 300;

    ${props =>
      props.hasError &&
      `
      color: ${color.support.red};
      `}
  }

  &:hover {
    ${props =>
      props.hasError
        ? `
      border-color: ${color.support.red};
      `
        : `border-color: ${color.primary}`};
    background-color: ${props => (props.hasError ? '#FEF7F7' : props.isValidated ? '#F6FAF4' : '#fff')};
  }
  &:focus {
    box-shadow: none;
  }

  ${props =>
    props.isValidated &&
    `
    border-color: #4caf50;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
    outline: none;
    `}

  ${props =>
    props.hasError &&
    `
      border-color: #d0021b;
      background-color: #fff;
      box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
      outline: none;
      color: #d0021b;
      `}

  @media only screen and (max-width: 850px) {
    font-size: 16px;
    line-height: 26px;
  }
`

export default Input
