import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Input from './Input'
import {
  validateEmail,
  validatePhoneNumber,
  validateOnBlur,
  scrollPage,
  validateName,
  appendUrlPartnerTracking,
  setItem,
  getItem
} from '../util/functions'

import ProductInfoCard from './ProductInfoCard'
import FormDivider from './FormDivider'
import Btn from './BtnForm'
import AddBtn from './BtnWithIcon'
import Checkbox from './Checkbox'
import theme from '../style/color'
import CustomerServiceBanner from './CustomerServiceBanner'
import plus from '../images/plus.svg'
import minus from '../images/minus.svg'
import ErrorMessage from './ErrorMessage'
import Loading from './Loading'

const DetailsForm = () => {
  const [name, setName] = useState(getItem('name'))
  const [nameError, toggleNameError] = useState(false)
  const [supName, setSupName] = useState(getItem('supName'))
  const [supNameError, toggleSupNameError] = useState(false)
  const [orgName, setOrgName] = useState(getItem('orgName'))
  const [orgNameError, toggleOrgNameError] = useState(false)
  const [phone, setPhone] = useState(getItem('phone'))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem('email'))
  const [emailError, toggleEmailError] = useState(false)
  const [supEmail, setSupEmail] = useState(getItem('supEmail'))
  const [supEmailError, toggleSupEmailError] = useState(false)
  const [agreeToMarketing, toggleAgreeToMarketing] = useState(getItem('agreeToMarketing'))
  const [agreeToTC, toggleAgreeToTC] = useState(getItem('agreeToTC'))
  const [termsError, toggleTermsError] = useState(false)
  const [showAdmin, toggleAdmin] = useState(false)
  const [product, setProduct] = useState(getItem('product') || 'monthly')
  const [emailExists, toggleEmailExists] = useState(false)
  const [subdomain, setSubdomain] = useState('')

  const [productPrices, setProductPrices] = useState({})
  const [seats, setSeats] = useState(getItem('seats') || 1)

  const [processing, toggleProcessing] = useState(true)

  const validateEmailExists = (): boolean => {
    if (!validateEmail(email)) {
      console.log('wrong')
      return toggleEmailError(true)
    }
    fetch(`${process.env.API_URL}/check-email-exists`, {
      headers: { Authorization: `Api-Key ${process.env.API_KEY}`, email }
    })
      .then(resp => resp.json())
      .then(data => {
        console.log(data)
        if (data.exists) {
          toggleEmailExists(true)
          setSubdomain(data.subdomain)
          return toggleEmailError(true)
        }
        toggleEmailExists(false)
        setSubdomain('')
        return toggleEmailError(false)
      })
  }

  useEffect(() => {
    fetch(`${process.env.API_URL}/get-products`, {
      headers: { Authorization: `Api-Key ${process.env.API_KEY}` }
    })
      .then(resp => resp.json())
      .then(data => {
        setProductPrices(data)
        toggleProcessing(false)
      })
      .catch(err => console.log(err))
  }, [])

  const backOnClick = () => {
    setItem('name', name)
    setItem('orgName', orgName)
    setItem('email', email)
    setItem('phone', phone)
    setItem('supName', showAdmin ? supName : name)
    setItem('supEmail', showAdmin ? supEmail : email)
    setItem('seats', seats)
    setItem('product', product)
    setItem('agreeToMarketing', agreeToMarketing)
    setItem('agreeToTC', agreeToTC)
    navigate(appendUrlPartnerTracking('/'))
  }

  const handleSubmit = async () => {
    let idToScroll = ''

    let nameError = false
    let orgNameError = false
    let emailError = false
    let phoneError = false
    let supNameError = false
    let supEmailError = false
    const termsError = false

    if (orgName.length < 1) {
      toggleOrgNameError(true)
      orgNameError = true
      idToScroll = 'orgName'
    }

    if (name.length < 1) {
      nameError = true
      toggleNameError(true)
      if (!idToScroll) {
        idToScroll = 'name'
      }
    }

    if (!validateEmail(email) && !emailExists) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = 'email'
      }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = 'phone'
      }
    }

    if (showAdmin && supName.length < 1) {
      supNameError = true
      toggleSupNameError(true)
      if (!idToScroll) {
        idToScroll = 'supName'
      }
    }

    if (showAdmin && !validateEmail(supEmail)) {
      supEmailError = true
      toggleSupEmailError(true)
      if (!idToScroll) {
        idToScroll = 'supEmail'
      }
    }

    // if (!agreeToTC) {
    //   termsError = true
    //   toggleTermsError(true)
    //   if (!idToScroll) {
    //     idToScroll = 'terms'
    //   }
    // }

    if (!orgNameError && !nameError && !emailError && !phoneError && !supNameError && !supEmailError) {
      setItem('name', name)
      setItem('orgName', orgName)
      setItem('email', email)
      setItem('phone', phone)
      setItem('supName', showAdmin ? supName : name)
      setItem('supEmail', showAdmin ? supEmail : email)
      setItem('seats', seats)
      setItem('product', product)
      setItem('agreeToMarketing', agreeToMarketing)
      setItem('agreeToTC', agreeToTC)
      window.location.href = appendUrlPartnerTracking('/billing-details')
    } else if (idToScroll) {
      scrollPage(idToScroll)
    }
  }

  return processing ? (
    <Loading />
  ) : (
    <ColumnsContainer>
      <Form action="/step-3" onSubmit={handleSubmit}>
        <FormDivider heading="Account details" />
        <Input
          value={orgName}
          onChange={val => val.length < 70 && setOrgName(val)}
          label="Company Name"
          isValidated={validateName(orgName)}
          hasError={orgNameError}
          errorTitle="Please enter your company's name"
          onBlur={() => validateOnBlur(validateName(orgName), toggleOrgNameError)}
          id="orgName"
          autoFocus
        />
        <Input
          value={name}
          onChange={(str: string) => {
            str.length < 70 && setName(str)
          }}
          label="Full Name"
          isValidated={validateName(name)}
          hasError={nameError}
          errorTitle="Please enter your name"
          onBlur={() => validateOnBlur(validateName(name), toggleNameError)}
          id="name"
        />
        <Input
          value={email}
          onChange={(str: string) => {
            str.length < 50 && setEmail(str)
          }}
          label="Email address"
          isValidated={validateEmail(email)}
          hasError={emailError}
          errorTitle={emailExists ? 'This email is already in use' : 'Please enter a valid email address'}
          subtitle={
            emailExists && (
              <>
                Looks like you already have an account with us. You can{' '}
                <a target="_blank" href={`https://${subdomain}.cyberlearn.app/login`}>
                  login
                </a>{' '}
                or try another email address.
              </>
            )
          }
          onBlur={validateEmailExists}
          id="email"
        />

        <Input
          value={phone}
          onChange={val => val.length < 20 && setPhone(val)}
          label="Contact phone number"
          isValidated={phone && validatePhoneNumber(phone)}
          hasError={phoneError}
          errorTitle="Please enter a valid telephone number"
          onBlur={() => validateOnBlur(validatePhoneNumber(phone), togglePhoneError)}
          id="phone"
          optional
        />
        <FormSpacing />
        <FormDivider heading="Team admin" />
        <AdminText>You can give someone else admin level access to your Bob's Business account.</AdminText>
        <AddBtn
          onClick={() => {
            toggleAdmin(!showAdmin)
            setSupName('')
            setSupEmail('')
          }}
          width="270px"
        >
          <strong>{showAdmin ? 'Remove' : 'Add'} admin user</strong> <img src={showAdmin ? minus : plus} alt="plus / minus" />
        </AddBtn>
        {showAdmin ? (
          <>
            <Input
              value={supName}
              onChange={val => val.length < 70 && setSupName(val)}
              label="Admin Name"
              isValidated={supName.length > 0}
              hasError={supNameError}
              errorTitle="Please enter your Admin name"
              onBlur={() => validateOnBlur(supName.length > 0, toggleSupNameError)}
              id="supName"
            />
            <Input
              value={supEmail}
              onChange={val => val.length < 70 && setSupEmail(val)}
              label="Admin email address"
              isValidated={validateEmail(supEmail)}
              hasError={supEmailError}
              errorTitle="Please enter a valid email address"
              onBlur={() => validateOnBlur(validateEmail(supEmail), toggleSupEmailError)}
              id="supEmail"
            />
          </>
        ) : (
          <AdminSubText>You can skip this and do this later.</AdminSubText>
        )}

        <CustomerServiceBanner />
        <BtnContainer>
          <Btn onClick={backOnClick}>Back</Btn>
          <Btn type="secondary" onClick={handleSubmit}>
            <strong>Next:</strong> Billing Information
          </Btn>
        </BtnContainer>
      </Form>
      <ProductInfoCard seats={seats} setSeats={setSeats} productPrices={productPrices} product={product} setProduct={setProduct} />
      <BtnContainerMobile>
        <Btn type="secondary" onClick={handleSubmit}>
          <strong>Next:</strong> Billing Information
        </Btn>
        <Btn onClick={backOnClick}>Back</Btn>
      </BtnContainerMobile>
    </ColumnsContainer>
  )
}

const AdminSubText = styled.p`
  color: #9b9b9b;
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
  line-height: 23px;
  margin-top: 10px;
`

const TermsContainer = styled.div`
  margin-top: 53px;

  @media only screen and (max-width: 850px) {
    margin-top: 39px;
  }
`

const AdminText = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  color: ${theme.primary};
  margin-top: 30px;
  margin-bottom: 22px;
`

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1077px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 850px) {
    display: none;
  }
`

const BtnContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    border-top: 1px solid #4caf50;
    margin-top: 38px;
    margin-bottom: 30px;
  }
`

const CheckboxContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 65px;
  margin-bottom: 50px;
  border-bottom: 1px solid ${theme.primary};

  @media only screen and (max-width: 850px) {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }
`

const ColumnsContainer = styled.div`
  display: flex;
  width: 92%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  align-items: flex-start;
  padding-bottom: 70px;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
`

const FormSpacing = styled.div`
  padding-top: 53px;
`

const Form = styled.form`
  width: 70%;
  padding: 40px 60px 100px 0;
  padding-bottom: 0;

  @media only screen and (max-width: 850px) {
    width: 100%;
    padding: 0;
    padding-top: 30px;
  }
`

export default DetailsForm
