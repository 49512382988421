import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'

interface BtnPrimaryRoundProps {
  children: React.ReactNode
  promptToClick?: boolean
  onClick: any
  width?: string
  type: string
}

const BtnPrimaryRound = ({ children, promptToClick, onClick, width, type = 'primary' }: BtnPrimaryRoundProps) => {
  return (
    <Container onClick={onClick} promptToClick={promptToClick} width={width} type={type}>
      {children}
    </Container>
  )
}

export default BtnPrimaryRound

interface IContainer {
  promptToClick?: boolean
}

const Container = styled.div<IContainer>`
  background: ${theme.primary};
  width: ${props => props.width};
  min-width: 256px;
  padding: 15px 23px;
  border-radius: 5px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: white;
  cursor: pointer;
  border: 1px solid ${theme.primary};
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;

  ${props =>
    props.type === 'secondary' &&
    `
    justify-content: center;
    align-items: center;
    background-color: ${theme.red};
    border-color: ${theme.red};

    & img {
      margin-right: 5px;
    }
  `};

  & img {
    width: 22px;
  }

  &:hover,
  &:hover div {
    background-color: #007994;

    ${props => props.type === 'secondary' && `background-color: ${theme.redHover};border-color:${theme.redHover}`}
  }

  @media only screen and (max-width: 850px) {
    font-size: 18px;
  }
  & b,
  & strong {
    font-weight: bold;
  }

    @media only screen and (max-width: 850px) {
      transform: scaleX(1) scaleY(1);
    }
  }


  ${props =>
    props.promptToClick &&
    `
    @keyframes scaleUpDown {
      0% {
      transform: scaleX(1) scaleY(1);
      }
      50% {
    transform: scaleX(1.03) scaleY(1.03);
      }
      100% {
      transform: scaleX(1) scaleY(1);
      }
    }

    animation-name: scaleUpDown;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    `};
`
