import React from 'react'
import styled from 'styled-components'

const UsersSideTable = ({ users, prices, product }) => {
  const getUsers = (price, i) => {
    if (!price.up_to) {
      return `${prices[i - 1].up_to + 1}+`
    }

    if (i === 0) {
      return `1-${price.up_to}`
    }

    return `${prices[i - 1].up_to + 1}-${price.up_to}`
  }

  const formattedPrices = prices.map((price, i) => {
    return {
      max: price.up_to,
      users: getUsers(price, i),
      price: `£${(price.unit_amount / (product === 'monthly' ? 1 : product === 'year' ? 12 : 36) / 100).toFixed(2)}/mo`
    }
  })

  const checkForBold = (price, i) => {
    if (!price.max) {
      if (users > formattedPrices[i - 1].max) {
        return true
      }
    }

    if (users <= price.max && users > (formattedPrices[i - 1]?.max || 0)) {
      return true
    }

    return false
  }

  return (
    <Container>
      <PriceContainer>
        {formattedPrices.map((price, i) => {
          return (
            <Price key={i} pink={checkForBold(price, i)}>
              <p>{price.users}</p>
              <p>{price.price}</p>
            </Price>
          )
        })}
      </PriceContainer>
    </Container>
  )
}

const Container = styled.div`
  // margin-top: 28px;
  margin-bottom: 10px;
`

const PriceContainer = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 850px) {
    // flex-direction: column;
  }
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  border-right: 1px solid #d8d8d8;

  &:first-of-type {
    border-left: 1px solid #d8d8d8;
  }

  & p:first-of-type {
    font-size: 15px !important;
    line-weight: 18px;
    font-weight: bold;
    ${props => props.pink && `color: #D80054;`}

    @media only screen and (max-width: 850px) {
      font-size: 12px !important;
    }
  }

  & p:last-of-type {
    font-size: 12px;
    font-weight: 300;
    color: #4a4a4a;
    ${props => props.pink && `color: #D80054;`}

    @media only screen and (max-width: 850px) {
      font-size: 9px;
    }
  }

  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`

export default UsersSideTable
