import React, { useState } from 'react'
import styled from 'styled-components'
import Btn from './BtnForm'
import theme from '../style/color'
import cross from '../images/logos/cross.svg'
import plus from '../images/plus.svg'
import minus from '../images/minus.svg'
import shield from '../images/shield.svg'
import { setItem } from '../util/functions'
import UsersSideTable from './UsersSideTable'

interface SeatsModalProps {
  seats: string
  setSeats(newSeats: string): void
  showModal: boolean
  toggleModal(state: boolean): void
  totalSection: any
  product: string
}

const SeatsModal = ({ seats, setSeats, showModal, toggleModal, totalSection, product, prices }: SeatsModalProps) => {
  const cardPrice = prices[(product === '3year' ? 'year3' : product) + `Price`]
  const [newSeats, setNewSeats] = useState(seats)

  const handleUpdate = (): void => {
    if (newSeats) {
      setSeats(newSeats)
      setItem('seats', newSeats)
      toggleModal(false)
    }
  }

  const handlePlus = (): void => {
    const value = Math.min(250, Number(newSeats) + 1).toString()
    setNewSeats(value)
  }

  const handleMinus = (): void => {
    const value = Math.max(1, Number(newSeats) - 1).toString()
    setNewSeats(value)
  }

  const handleOnChange = (e: any): void => {
    if (!e.target.value || (typeof Number(e.target.value) === 'number' && Number(e.target.value) < 251 && Number(e.target.value) >= 1)) {
      !e.target.value ? setNewSeats('') : setNewSeats(parseInt(e.target.value, 10))
    }
  }

  return (
    <OuterContainer showModal={showModal} onClick={() => toggleModal(false)}>
      <Modal onClick={e => e.stopPropagation()}>
        <Cross src={cross} onClick={() => toggleModal(false)} />
        <p>How many users do you need? (max 250)</p>
        <InputContainer>
          <img src={minus} onClick={handleMinus} />
          <input value={newSeats} min={1} max={250} onChange={handleOnChange} />
          <img src={plus} onClick={handlePlus} />
        </InputContainer>
        <UsersSideTable users={newSeats} prices={cardPrice} product={product} />
        <Line />
        {totalSection(newSeats)}
        <Btn type="secondary" onClick={handleUpdate}>
          Update
        </Btn>
        {product === 'monthly' && (
          <ListItem>
            <img src={shield} alt="No commitment, cancel at any time" />
            <p>No commitment, cancel at any time.</p>
          </ListItem>
        )}
      </Modal>
    </OuterContainer>
  )
}

const Line = styled.div`
  height: 3px;
  width: 100%;
  background-color: black;
  margin-top: 7px;
  margin-bottom: 4px;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  & img {
    width: 30px;
    height: 30px;
    padding: 7px;
    background: ${theme.primary};
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: ${theme.primaryHighlight};
    }
  }
`

const Cross = styled.img`
  cursor: pointer;
  width: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
`

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 999;

  @media only screen and (max-width: 850px) {
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.9);
  }
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 50px;
  padding-top: 60px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 520px;
  position: relative;
  z-index: 1000;

  @media only screen and (max-width: 850px) {
    border-radius: 0;
    width: 100%;
    padding: 25px;
  }

  & input {
    margin: 15px;
    width: 105px;
    height: 60px;
    font-size: 30px;
    font-weight: bold;
    color: ${theme.primary};
    text-align: center;
    border-radius: 4px;
    border: 1px solid ${theme.primary};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  & > p {
    color: ${theme.text};
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 16px;
  }

  h3 {
    font-family: Roboto;
    font-size: 30px;
    line-height: 35px;
    color: ${theme.secondary};
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 10px;
    padding-top: 10px;
    & span {
      font-weight: 300;
    }
  }
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
  color: ${theme.primary};

  & p {
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
  }

  & img {
    height: 32px;
    margin-right: 15px;
  }
`

export default SeatsModal
