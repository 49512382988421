import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { setItem, numberWithCommas, calculateUnitAmount } from '../util/functions'
import theme from '../style/color'
import SeatsModal from './SeatsModal'
import trolley from '../images/shopping-trolley.svg'
import person from '../images/seats.svg'
import shield from '../images/shield.svg'
import up from '../images/monitor.svg'
import id from '../images/access.svg'

interface OuterContainerProps {
  sticky: boolean
}

interface ProductInfoCardProps {
  seats: number | string
  setSeats(seats: number | string): void
  productPrices: any
  product: string
  setProduct(product: string): void
}

const ProductInfoCard = ({ seats, setSeats, productPrices, product, setProduct }: ProductInfoCardProps) => {
  const [showModal, toggleModal] = useState(false)

  const calcTotal = (seats, productType): string => {
    const type = productType || product
    const prices = type === 'year' ? productPrices.yearPrice : type === '3year' ? productPrices.year3Price : productPrices.monthlyPrice
    const unitAmount = calculateUnitAmount(prices, seats)
    return numberWithCommas(unitAmount * seats)
  }

  const calcSaving = (): number => {
    if (productPrices) {
      return Math.ceil(
        (calculateUnitAmount(productPrices.yearPrice, seats) / (calculateUnitAmount(productPrices.year3Price, seats) / 3) - 1) * 100
      )
    } else {
      return 0
    }
  }

  const totalSection = (newSeats: number) => {
    const showPricing = (newSeats: number): string => {
      if (product === 'monthly') {
        return `£${numberWithCommas(calculateUnitAmount(productPrices.monthlyPrice, newSeats))} user / month`
      } else {
        return `£${
          product === 'year'
            ? numberWithCommas(calculateUnitAmount(productPrices.yearPrice, newSeats))
            : numberWithCommas(calculateUnitAmount(productPrices.yearPrice, newSeats) / 3)
        } user / year`
      }
    }

    return (
      <>
        <SeatsContainer>
          <h3>
            Total<span> (inc vat)</span>
          </h3>
          {newSeats && <p>{showPricing(newSeats)}</p>}
        </SeatsContainer>
        <PriceContainer modal={newSeats}>
          <Price>£{calcTotal(newSeats)}</Price>
          {product === 'monthly' && <span>/mo</span>}
        </PriceContainer>
      </>
    )
  }

  return (
    <>
      <SeatsModal
        product={product}
        seats={seats}
        setSeats={setSeats}
        showModal={showModal}
        toggleModal={toggleModal}
        totalSection={totalSection}
        prices={productPrices}
      />
      <OuterContainer sticky={true}>
        <Container>
          <Heading>Summary</Heading>
          <img src={trolley} alt="Shopping trolley" />
        </Container>
        <ContentContainer>
          <SeatsHeading>
            <p>Number of users</p>
            <SmallBtn onClick={() => toggleModal(true)}>Update</SmallBtn>
          </SeatsHeading>
          <SeatsCount>
            <p>{seats}</p>
            <img src={person} />
          </SeatsCount>
          {totalSection(seats)}

          {product !== 'monthly' && (
            <ToggleContainer saving={calcSaving()}>
              <div>
                <h3>1 YEAR</h3>
                <p>£{calcTotal(seats, 'year')}</p>
                <ToggleBtn
                  isSelected={product === 'year'}
                  onClick={() => {
                    setProduct('year')
                    setItem('product', 'year')
                  }}
                />
              </div>
              <div>
                <h3>3 YEAR</h3>
                <p>£{calcTotal(seats, '3year')}</p>
                <ToggleBtn
                  green
                  isSelected={product === '3year'}
                  onClick={() => {
                    setProduct('3year')
                    setItem('product', '3year')
                  }}
                />
              </div>
            </ToggleContainer>
          )}

          {product === 'monthly' ? (
            <>
              <ProductDesc>
                <strong>You've chosen to pay monthly</strong>
                <p>
                  Your subscription will renew automatically. Your next renewal date will be{' '}
                  {moment()
                    .add(1, 'months')
                    .format('DD MMMM YYYY')}
                  .
                </p>
              </ProductDesc>
              <Btn onClick={() => setProduct('year')}>Switch to annual - Save {calcSaving()}%</Btn>
            </>
          ) : (
            <>
              <ProductDesc>
                <strong>You've chosen to pay annually</strong>
                <p>
                  You are starting a {product === '3year' ? 3 : 1} year annual subscription and will be charged £{calcTotal(seats)} today.
                  {product === 'year'
                    ? ` Your next renewal date will be ${moment()
                        .add(1, 'years')
                        .format('DD MMMM YYYY')}. You can cancel any time before this date.`
                    : ` Your subscription will expire
                    ${moment()
                      .add(3, 'years')
                      .format('DD MMMM YYYY')}
                      .`}
                </p>
              </ProductDesc>
              <Btn onClick={() => setProduct('monthly')}>Switch to monthly</Btn>
            </>
          )}

          <List>
            {product === 'monthly' && (
              <ListItem>
                <img src={shield} alt="No commitment, cancel at any time" />
                <p>No commitment, cancel at any time.</p>
              </ListItem>
            )}
            <ListItem>
              <img src={id} alt="Unlimited access to courses" />
              <p>Unlimited access to XXX courses.</p>
            </ListItem>
            <ListItem>
              <img src={up} alt="Monitor and measure learning" />
              <p>Monitor and measure learning.</p>
            </ListItem>
          </List>
        </ContentContainer>
      </OuterContainer>
    </>
  )
}

export default ProductInfoCard

const SeatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & p {
    color: #9b9b9b;
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    line-height: 23px;
  }

  & h3 {
    color: ${theme.primary};
  }
`

const ToggleContainer = styled.div`
  display: flex;

  & > div {
    padding: 10px 0;
    flex: 1;
    border: 1px solid #eee;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:nth-of-type(2) {
      &:after {
        content: "SAVE ${props => props.saving}%";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -12px;
        background: #F7A823;
        border-radius: 8px;
        color: white;
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        padding: 3px 5px;
      }
    }

    & h3 {
      color: ${theme.primary};
      padding: 0;
    }

    & p {
      font-size: 13px;
      font-weight: 300;
      line-height: 15px;
      color: ${theme.primary};
    }

    &:nth-of-type(2) {
      & h3 {
        color: ${theme.primary};
      }

      & p {
        color: ${theme.primary};
      }
    }
  }
`

const ToggleBtn = styled.div`
  margin-top: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 18px;
    width: 18px;
    ${props =>
      props.isSelected &&
      `
      border-radius: 50%;
      background: ${!props.green ? theme.secondary : theme.secondary};
    `};
  }
`

const Btn = styled.div`
  border: 1px solid ${theme.red};
  border-radius: 5px;
  color: ${theme.red};
  background: white;
  padding: 12px 35px;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;

  transition: background-color 200ms ease, color 200ms ease, background-color 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;

  &:hover,
  &:hover div {
    background-color: ${theme.red};
    color: white;
  }
`

const SmallBtn = styled(Btn)`
  font-size: 12px;
  padding: 5px 20px;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & p {
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
  }

  & img {
    height: 32px;
    margin-right: 15px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  padding: 30px;

  h3 {
    font-family: Roboto;
    font-size: 30px;
    line-height: 35px;
    color: ${theme.primary};
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 10px;
    padding-top: 10px;
    & span {
      font-weight: 300;
    }
  }
`

const SeatsHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & p {
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }
`

const SeatsCount = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 3px solid black;
  padding-bottom: 10px;

  & p {
    font-size: 50px;
    line-height: 58px;
    font-weight: bold;
    margin-right: 10px;
  }
  color: ${theme.primary};
  & img {
    height: 37px;
  }
`

const PriceContainer = styled.div`
  display: flex;
  color: ${theme.primary};
  font-size: 30px;
  & span {
    font-weight: 300;
  }

  padding-bottom: 25px;
  ${props => props.modal && `border-bottom: 1px solid #eee;`};
`

const Price = styled.p`
  font-size: 40px;
  font-weight: 600;
`

const ProductDesc = styled.div`
  & strong {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 23px;
    margin-top: 20px;
  }

  & p {
    color: #4a4a4a;
    font-size: 15px;
    font-weight: 300;
    line-height: 23px;
  }

  margin-bottom: 17px;
`

const List = styled.div`
  color: ${theme.primary};
  margin-top: 20px;
`

const OuterContainer = styled.div<OuterContainerProps>`
  // position: ${props => (props.sticky ? 'sticky' : 'block')};
  position: sticky;
  top: 30px;
  width: 100%;
  max-width: 370px;
  margin-top: -60px;

  @media only screen and (max-width: 850px) {
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    position: relative;
    top: 0;
  }
`

const Heading = styled.p`
  font-size: 17px;
  line-height: 27px;
  font-weight: bold;
`

const Container = styled.div`
  position: relative;
  padding: 20px 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  background: ${theme.primary};
  align-items: center;
  justify-content: space-between;
  display: flex;
  & img {
    height: 40px;
    @media only screen and (max-width: 850px) {
      height: 30px;
    }
  }
`
