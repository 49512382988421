import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'
import profile from '../images/Profile icon.png'

const CustomerServiceBanner = () => {
  return (
    <Container>
      <img src={profile} />
      <p>
        Hi, i’m Sandy your Customer Services Manager, if you need any help please click the chat box in bottom right corner to speak to a
        member of my team
      </p>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 30px;

  @media only screen and (max-width: 850px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  & img {
    height: 60px;
    width: 60px;
    margin-right: 20px;
    flex-shrink: 0;
  }

  & p {
    color: ${theme.text};
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    line-height: 23px;
  }
`

export default CustomerServiceBanner
