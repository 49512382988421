import React, { useEffect, useState } from 'react'
import IndexLayout from '../layouts'
import DetailsForm from '../components/DetailsForm'
import { setItem } from '../util/functions'

const qs: Array<IQuestions> = [
  {
    question: 'Can I add extra users at a later date?',
    answer: (
      <p>
        Yes. If required you can add additional users at any point in your subscription, regardless of which subscription option you choose
        (up to a maximum of 250 users total).
      </p>
    )
  },
  {
    question: 'Why am I paying annually?',
    answer: (
      <p>
        If you have chosen a 1-year or 3-year plan, the total cost is provided at a discount and billed annually. Otherwise, if you’re on a
        monthly plan, you will be billed on a monthly basis.
      </p>
    )
  },
  {
    question: 'Can I change these details later?',
    answer: (
      <p>
        Yes. Within the learning management system you can change your details at any time, and even list another person as the organisation
        owner so that they can take over the billing element if needed.
      </p>
    )
  },
  {
    question: 'Where do I enrol my users?',
    answer: (
      <p>
        Once you have completed your purchase, you will be sent a link to access your new learning portal. From there, you will be able to
        manage your users and group them exactly as you would like through a user-friendly interface.
      </p>
    )
  }
]

const IndexPage = () => {
  return (
    <IndexLayout
      title="Account Details - Bob's Business"
      heading={"Let's get started"}
      body="Some assembly required to get started"
      currentPage={1}
      qs={qs}
    >
      <DetailsForm />
    </IndexLayout>
  )
}

export default IndexPage
